import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { track } from '../../utils/analytics';
import {
    AppBar,
    Toolbar,
    IconButton,
    Button,
    Box,
    Container,
    List,
    Tooltip,
    Stack,
    useTheme,
} from '@mui/material';
import { CampaignOutlined, MenuOutlined, SettingsOutlined } from '@mui/icons-material';

import Routes from '../../constants/routes.js';
import BrandLogo from '../../../assets/refnow-logo-white.svg';
import { getCandidates } from '../../actions/candidateActions.js';
import { postLogout } from '../../actions/userActions.js';
import SettingsMenu from './settings-menu.js';
import NavigationButton from './menu-button.js';
import NavigationDrawer from './mobile-drawer.js';
import NavigationMenu from './menu.js';

export const NAV_HEIGHT = '60px';

const liveHidden = true;

export function NavigationBar({
    getCandidates,
    user,
    postLogout,
    newRefRequestBtnClick,
    closeRefRequestPanel = () => {},
}) {
    const { formatMessage } = useIntl();
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const [activeTab, setActiveTab] = useState('employer');
    const [referencingAnchorEl, setReferencingAnchorEl] = useState(null);
    const [inboundReferencingAnchorEl, setInboundReferencingAnchorEl] = useState(null);
    const [recruitAnchorEl, setRecruitAnchorEl] = useState(null);
    const [accountAnchorEl, setAccountAnchorEl] = useState(null);
    const navigate = useNavigate();
    const { isAdmin, isSuperAdmin } = user;
    const showAdminItems = isAdmin || isSuperAdmin;

    const handleAccountClick = (event) => {
        track('Opened Account menu');
        setAccountAnchorEl(event.currentTarget);
    };
    const handleAccountClose = (event, reason) => {
        if (window.TOUR_ACTIVE && reason === 'backdropClick') {
            return null;
        } else {
            setAccountAnchorEl(null);
        }
    };
    const [loadCustomUrl, setLoadCustomUrl] = useState(false);

    const handleReferenceRequest = () => {
        open && setOpen(false);
        track('New Request Open');
        newRefRequestBtnClick();
    };

    const handleOpen = (event, anchorEl, setAnchorEl) => {
        if (anchorEl !== event.currentTarget) {
            setAnchorEl(event.currentTarget);
        }
    };

    const handleClose = (setAnchorEl) => {
        setAnchorEl(null);
        closeRefRequestPanel();
    };

    const navigationItems = [
        {
            id: 'navigation-referencing',
            tabId: 'employer',
            anchorEl: referencingAnchorEl,
            setAnchorEl: setReferencingAnchorEl,
            handleClose,
            labelId: 'Header.referencing.title',
            menuId: 'referencing-menu',
            menuButtonId: 'referencing-button',
            menuItems: [
                {
                    id: 'navigation-requests',
                    labelId: 'Header.referencing.requests',
                    onClick: () => {
                        setActiveTab('employer');
                        getCandidates();
                        navigate(Routes.employer);
                        closeRefRequestPanel();
                    },
                },
                {
                    id: 'navigation-referencing-dashboard',
                    labelId: 'Header.referencing.dashboard',
                    onClick: () => {
                        setActiveTab('employer');
                        track('Navigate to Referencing Dashboard');
                        navigate(Routes.employerDashboard);
                    },
                },
                {
                    id: 'navigation-questions',
                    labelId: 'Header.referencing.questionProfiles',
                    onClick: () => {
                        setActiveTab('employer');
                        navigate(Routes.adminQuestionProfiles);
                    },
                    condition: showAdminItems, // This condition determines if this item should be displayed
                },
                {
                    id: 'navigation-reporting',
                    labelId: 'Header.referencing.reporting',
                    onClick: () => {
                        track('Navigate to Reporting page');
                        window.open(
                            'https://api.refnow.co/v1/reporting/html-reports/candidates',
                            '_blank',
                            ''
                        );
                    },
                },
            ],
        },
        {
            id: 'navigation-inbound',
            tabId: 'inbound',
            anchorEl: inboundReferencingAnchorEl,
            setAnchorEl: setInboundReferencingAnchorEl,
            handleClose,
            labelId: 'Header.inbound.title',
            menuId: 'inbound-menu',
            menuButtonId: 'inbound-referencing-button',
            menuItems: [
                {
                    id: 'navigation-inbound-dashboard',
                    labelId: 'Header.inbound.dashboard',
                    onClick: () => {
                        setActiveTab('inbound');
                        track('Navigate to Inbound Dashboard');
                        navigate(Routes.inbound);
                    },
                },
                {
                    id: 'navigation-inbound-manager',
                    labelId: 'Header.inbound.manager',
                    onClick: () => {
                        track('Navigate to Inbound Manager page');
                        return window.open(
                            'https://api.refnow.co/v1/html/inbound/list',
                            '_blank',
                            ''
                        );
                    },
                    condition: showAdminItems, // This condition determines if this item should be displayed
                },
            ],
        },
        {
            id: 'navigation-recruit',
            tabId: 'recruit',
            anchorEl: recruitAnchorEl,
            setAnchorEl: setRecruitAnchorEl,
            handleClose,
            labelId: 'Header.recruit.title',
            menuId: 'recruit-menu',
            menuButtonId: 'recruit-button',
            menuItems: [
                {
                    id: 'navigation-talent-pool',
                    labelId: 'Header.recruit.talentPool',
                    onClick: () => {
                        setActiveTab('recruit');
                        track('Navigate to Talent Pool');
                        navigate(Routes.talentPool);
                    },
                },
            ],
        },
    ];

    const [drawerOpen, setDrawerOpen] = useState(false);

    const toggleDrawer = (open) => () => {
        setDrawerOpen(open);
    };

    const getBrandLogo = () =>
        loadCustomUrl ? (
            <img
                src={`https://assets.refnow.co/app/custom/logo/${window.location.hostname}.png`}
                alt="Custom Branding Logo"
                onClick={() => {
                    getCandidates();
                    closeRefRequestPanel();
                }}
            />
        ) : (
            <BrandLogo
                alt="RefNow Logo"
                width="120px"
                height="50px"
                onClick={() => {
                    getCandidates();
                    closeRefRequestPanel();
                }}
            />
        );

    useEffect(() => {
        const hostname = window.location.hostname;
        const refnowUrls = [
            'app.refnow.co',
            'app.refnow.com',
            'localhost',
            'cryptic-silkworm.refnow.co',
        ];

        if (hostname.includes('herokuapp.com')) {
            setLoadCustomUrl(false);
        } else if (refnowUrls.includes(hostname)) {
            setLoadCustomUrl(false);
        } else {
            setLoadCustomUrl(true);
        }

        if (window.Canny) {
            window.Canny('initChangelog', {
                appID: '6406b1316f483a21ea656317',
                position: 'bottom',
                align: 'right',
            });
        }
    }, []);

    useEffect(() => {
        Object.entries(Routes).find(([key, value]) => {
            console.log(location.pathname);
            if (location.pathname.includes(value)) {
                if (location.pathname.includes('inbound')) {
                    setActiveTab('inbound');
                } else if (location.pathname.includes('talentPool')) {
                    setActiveTab('recruit');
                } else {
                    setActiveTab(key);
                }

                return true;
            }
        });
    }, [location.pathname]);

    return (
        <>
            <AppBar
                position="static"
                sx={{
                    height: NAV_HEIGHT,
                    borderRadius: '0 !important',
                    bgcolor: 'secondary.main',
                    justifyContent: 'center',
                }}
            >
                <Container maxWidth="xxxl">
                    <Toolbar disableGutters sx={{ justifyContent: 'space-between' }}>
                        <Box display="flex" flexDirection="row">
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                edge="start"
                                onClick={toggleDrawer(true)}
                                sx={{ mr: 2, display: { lg: 'none' } }}
                            >
                                <MenuOutlined />
                            </IconButton>
                            {/* Website Logo */}
                            <IconButton
                                color="inherit"
                                aria-label="logo"
                                id="navigation-logo"
                                sx={{
                                    width: '10rem',
                                    display: { xs: 'none', md: 'flex' },
                                    justifyContent: 'flex-start',
                                }}
                                onClick={() => {
                                    setActiveTab('employer');
                                    navigate(Routes.employer);
                                }}
                            >
                                {getBrandLogo()}
                            </IconButton>
                        </Box>

                        <Container
                            maxWidth="xl"
                            sx={{ flexGrow: 1, display: { xs: 'none', lg: 'block' } }}
                        >
                            {/* Main Menus */}
                            <List component={Stack} direction="row" gap={3}>
                                {navigationItems.map((item) => (
                                    <>
                                        <NavigationButton
                                            key={item.id}
                                            id={item.id}
                                            selected={activeTab === item.tabId}
                                            anchorEl={item.anchorEl}
                                            labelId={item.labelId}
                                            menuId={item.menuId}
                                            onOpen={(event) =>
                                                handleOpen(event, item.anchorEl, item.setAnchorEl)
                                            }
                                        />
                                        <NavigationMenu {...item} />
                                    </>
                                ))}
                            </List>
                        </Container>
                        {/* Settings and User Icons */}
                        <Box display="flex" gap={2} alignItems="center">
                            <Tooltip title={formatMessage({ id: 'Header.announcements.title' })}>
                                <IconButton
                                    data-canny-changelog
                                    onClick={() => track('Opened Announcements menu')}
                                    id="navigation-announcements"
                                    color="inherit"
                                    size="large"
                                    sx={{
                                        '&:hover': {
                                            backgroundColor: theme.palette.secondary.light,
                                        },
                                    }}
                                    aria-label="announcements menu"
                                    aria-haspopup="true"
                                >
                                    <CampaignOutlined
                                        sx={{
                                            color: theme.palette.common.white,
                                            // fontSize: 32,
                                        }}
                                    />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={formatMessage({ id: 'Header.account.title' })}>
                                <IconButton
                                    onClick={handleAccountClick}
                                    id="navigation-account"
                                    color="inherit"
                                    size="large"
                                    sx={{
                                        '&:hover': {
                                            backgroundColor: theme.palette.secondary.light,
                                        },
                                    }}
                                    aria-label="settings menu"
                                    aria-controls={accountAnchorEl ? 'account-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={accountAnchorEl ? 'true' : undefined}
                                >
                                    <SettingsOutlined
                                        sx={{
                                            color: theme.palette.common.white,
                                        }}
                                    />
                                </IconButton>
                            </Tooltip>

                            <SettingsMenu
                                anchorEl={accountAnchorEl}
                                handleClose={handleAccountClose}
                                user={user}
                                liveHidden={liveHidden}
                                postLogout={postLogout}
                            />
                            <Button
                                id="navigation-new-reference-request"
                                color="primary"
                                variant="contained"
                                // size="large"
                                data-intercom-target="new_reference_request"
                                sx={{ margin: '10px', px: 4, textWrap: 'nowrap' }}
                                onClick={handleReferenceRequest}
                            >
                                {formatMessage({ id: 'Header.requestButton' })}
                            </Button>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
            <NavigationDrawer
                open={drawerOpen}
                onClose={toggleDrawer(false)}
                items={navigationItems}
                getBrandLogo={getBrandLogo}
            />
        </>
    );
}

const mapStateToProps = ({ user }) => ({ user });

const mapDispatchToProps = {
    getCandidates,
    postLogout,
};

export default connect(mapStateToProps, mapDispatchToProps)(NavigationBar);
